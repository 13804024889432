import axios from "./axios";
const api = {};

api.register = function (body) {
    const url = `${process.env.REACT_APP_SERVER_BASE_URL}/user/register`;
  
    return axios.post(url, body);
  };
  
api.login = function (body) {
    const url = `${process.env.REACT_APP_SERVER_BASE_URL}/user/login`;
    return axios.post(url, body);
};
api.forgetPass = function (body) {
    const url = `${process.env.REACT_APP_SERVER_BASE_URL}/user/forget-password`;
    return axios.post(url, body);
};
api.resetPass = function (body) {
    const url = `${process.env.REACT_APP_SERVER_BASE_URL}/user/reset-password`;
    return axios.post(url, body);
};
api.adminLogin = function (body) {
    const url = `${process.env.REACT_APP_SERVER_BASE_URL}/admin/login`;
    return axios.post(url, body);
};
api.allUsers = function (body) {
    const url = `${process.env.REACT_APP_SERVER_BASE_URL}/admin/users`;
    return axios.post(url, body);
};
api.deleteUser = function (body) {
    const url = `${process.env.REACT_APP_SERVER_BASE_URL}/admin/delete/user`;
    return axios.post(url, body);
};
api.allSurvey = function (body) {
    const url = `${process.env.REACT_APP_SERVER_BASE_URL}/admin/survey`;
    return axios.post(url, body);
};
api.sendMailToAllUser = function (body) {
    const url = `${process.env.REACT_APP_SERVER_BASE_URL}/admin/send-mail-to-all-user`;
    return axios.post(url, body);
};
api.sendPostMailToAllUser = function (body) {
    const url = `${process.env.REACT_APP_SERVER_BASE_URL}/admin/send-post-mail-to-all-user`;
    return axios.post(url, body);
};
api.verifySession = function (body) {
    const url = `${process.env.REACT_APP_SERVER_BASE_URL}/user/check/session`;
    return axios.post(url, body);
};
api.survey = function (body) {
    const url = `${process.env.REACT_APP_SERVER_BASE_URL}/user/survey`;
    return axios.post(url, body);
};
api.userEdit = function (body) {
    const url = `${process.env.REACT_APP_SERVER_BASE_URL}/user/edit`;
    return axios.post(url, body);
};
api.verifyEmail = function (body) {
    const url = `${process.env.REACT_APP_SERVER_BASE_URL}/user/verifyOTP`;
    return axios.post(url, body);
};
api.uploadFile = function (body) {
    const url = `${process.env.REACT_APP_SERVER_BASE_URL}/file/upload`;
    return axios.post(url, body);
  };
api.postCreate = function (body) {
    const url = `${process.env.REACT_APP_SERVER_BASE_URL}/post/new`;
    return axios.post(url, body);
};
api.postEdit = function (body) {
    const url = `${process.env.REACT_APP_SERVER_BASE_URL}/post/edit`;
    return axios.patch(url, body);
};
api.postDelete = function (body) {
    const url = `${process.env.REACT_APP_SERVER_BASE_URL}/post/delete`;
    return axios.delete(url, body);
};

api.postGet = function (body) {
  const url = `${process.env.REACT_APP_SERVER_BASE_URL}/post/get`;
  return axios.post(url, body);
};
api.getDataInfo = function () {
  const url = `${process.env.REACT_APP_SERVER_BASE_URL}/admin/datainfo`;
  return axios.get(url);
};
api.getUserByMail = function (body) {
    const url = `${process.env.REACT_APP_SERVER_BASE_URL}/admin/get/userbymail`;
    return axios.post(url, body);
};
  export default api;
