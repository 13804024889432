import React,{useEffect,useState} from 'react';
import { NavLink } from "react-router-dom";
import axios from "axios";


import edit from "../../images/edit.svg";
import Delete from "../../images/delete.svg";
import Logo from "../../images/logo-2.png"
import { useSelector } from 'react-redux';
import api from '../../api';
import { Pagination, Stack } from '@mui/material';


function AllSurvey({match}) {
  const [page, setPage]=useState(1)
  const [limit, setLimit]=useState(10)
  const [data, setData]=useState([])
  const [noOfUser, setnoOfUser]=useState(0)

  // console.log(window.location);
  // console.log(match.url);
  let payload = {
    page:page
  }
  const getUsers = async()=>{
    try {
      const userData = await api.allSurvey(payload)
      // console.log(userData);
      setData(userData.survey)
      setnoOfUser(userData.count)
      setLimit(userData.limit)
    } catch (error) {
      console.log(error);
    }
  }

  useEffect(() => {
    getUsers()
  
  }, [page])
  

    return(
      <>
                  {/* Begin Page Content */}
                    <div className="container-fluid">
                     {/* Content Row */}
                      <div className="row">
                      {/* Area Chart */}
                      <div className="col">
                         <div className="row mx-0 drivers-row">
                             <div className="col-xl-6 col-md-7  d-flex justify-content-between pr-0">
                                 <h4 className="color-dark fs-17 dib font-weight-bold mr-5">Surveys ({noOfUser})</h4>
                                 {/* <a href="#" className="driver-row-link">All</a>
                                 <a href="#" className="driver-row-link">Arbitrators (86)</a>
                                 <a href="#" className="driver-row-link">Mediators (32)</a> */}
                             </div>
                          
                         </div>
                         {/* table */}
                         <Table data={data} setPage={setPage} noOfUser={noOfUser} limit={limit} />

                      </div>
                      
                    </div>
              
                  </div>
                  {/* /.container-fluid */}
         
      </>
    )
  }
  const Table=({data, page, setPage, noOfUser ,limit})=>{
    // const authedUser = useSelector(s => s.authedUser.authedUser);

   
    const [loader,setLoader]=useState(false)

    const handleChange = (event, value) => {
      setPage(value);
    };



    return(
      <>
      <div className="card sortby-box br-10 border-transparent mt-3"> 
        <div className="card-header br-10  bg-white border-transparent py-3 ">
          <div className="table-responsive">
            <table className="table table-hover drivers-table">
              <thead>
                <tr>
                  <th scope="col" className="">FirstName</th>
                  <th scope="col" className="">lastName</th>
                  <th scope="col" className="">email</th>
                  <th scope="col" className="">phoneNumber</th>
                  <th scope="col" className="">opinion</th>
                  <th scope="col" className="">madeMoney</th>
                  <th scope="col" className="">Time Prefer</th>
                  <th scope="col" className="">Submited At</th>
                </tr>
              </thead>
              <tbody>
              {data.map((item,key)=>(
                <tr key={key}>
                  <td scope="row" className="d-flex">
                    <div className="table-text-dark ">{item?.firstName}</div>
                  </td>
                  <td>
                    <span className="table-text-dark w-max-c db">{item?.lastName}</span>
                  </td>
                  <td>
                    <span className="table-text-dark w-max-c db">{item?.email}</span>
                  </td>
                  <td>
                    <span className="table-text-dark w-max-c db">{item?.phoneNumber}</span>
                  </td>
                  <td>
                    <span className="table-text-dark w-max-c db">{item?.opinion}</span>
                  </td>
                  <td>
                    <span className="table-text-dark w-max-c db">{item?.madeMoney}</span>
                  </td>
                  <td>
                    <span className="table-text-dark w-max-c db">{item?.timePrefer}</span>
                  </td>
                  <td>
                    <span className="table-text-dark w-max-c db">{item?.createdAt}</span>
                  </td>
                
                </tr>
              ))}
            
              </tbody>
            </table>
          </div>
          <div className="d-flex justify-content-center">
            <Stack spacing={2}>
              <Pagination count={Math.ceil(noOfUser/limit)} page={page} onChange={handleChange} />
            </Stack>
          </div>
      </div>

    </div>

      {loader &&
          <div className="seekoLoader">
              <div className="loader">
                  <img src={Logo} alt="loader" />
              </div>
          </div>
      }
      </>
    )
  }

  export default AllSurvey