import React,{useEffect,useState} from 'react';
import { NavLink } from "react-router-dom";
import axios from "axios";


import edit from "../../images/edit.svg";
import Delete from "../../images/delete.svg";
import Logo from "../../images/logo-2.png"
import { useSelector } from 'react-redux';
import api from '../../api';
import { Pagination, Stack } from '@mui/material';
import { Loader } from './loader';


function AllAdminPost({match}) {
  const [page, setPage]=useState(1)
  const [limit, setLimit]=useState(10)
  const [data, setData]=useState([])
  const [noOfPost, setnoOfPost]=useState(0)
  const [loader,setLoader]=useState(true)

  // console.log(window.location);
  // console.log(match.url);
  const getPosts = async()=>{
    try {
      let payload = {
        page:page
      }
      setLoader(true)
      const postData = await api.postGet(payload)
      // console.log(postData);
      setData(postData.post)
      setnoOfPost(postData.count)
      setLimit(postData.limit)
      setLoader(false)
    } catch (error) {
      console.log(error);
      setLoader(false)
    }
  }
  const deletePost = async(id)=>{
    try {
      let payload = {
        id:id
      }
      setLoader(true)
      const userData = await api.postDelete(payload)
      console.log("deleted");
      // console.log(userData);
      getPosts()
      setLoader(false)
    } catch (error) {
      console.log(error);
      setLoader(false)
    }
  }


  useEffect(() => {
    getPosts()
  
  }, [page])
  

    return(
      <>
                  {/* Begin Page Content */}
                    <div className="container-fluid">
                     {/* Content Row */}
                      <div className="row">
                      {/* Area Chart */}
                      <div className="col">
                         <div className="row mx-0 drivers-row">
                             <div className="col-xl-6 col-md-7  d-flex justify-content-between pr-0">
                                 <h4 className="color-dark fs-17 dib font-weight-bold mr-5">Post ({noOfPost})</h4>
                                 {/* <a href="#" className="driver-row-link">All</a>
                                 <a href="#" className="driver-row-link">Arbitrators (86)</a>
                                 <a href="#" className="driver-row-link">Mediators (32)</a> */}
                             </div>
                          
                         </div>
                         {/* table */}
                         <Table data={data} setPage={setPage} page={page} noOfPost={noOfPost} limit={limit} deletePost={deletePost} />

                      </div>
                      
                    </div>
              
                  </div>
                  {/* /.container-fluid */}
                  {loader && 
                  <Loader />
                  }
         
      </>
    )
  }
  const Table=({data, page, setPage, noOfPost ,limit, deletePost})=> {
    // const authedUser = useSelector(s => s.authedUser.authedUser);

    const handleChange = (event, value) => {
      setPage(value);
    };



    return(
      <>
      <div className="card sortby-box br-10 border-transparent mt-3"> 
        <div className="card-header br-10  bg-white border-transparent py-3 ">
          <div className="table-responsive">
            <table className="table table-hover drivers-table">
              <thead>
                <tr>
                  <th scope="col" className="">Image</th>
                  <th scope="col" className="">Description</th>
                  <th scope="col"></th>
                </tr>
              </thead>
              <tbody>
              {data.map((item,key)=>(
                <tr key={key}>
                
                  <td>
                    <span className="table-text-dark w-max-c db">
                        <div className="pImage" style={{maxWidth:130}}>
                            <img 
                            src={`${process.env.REACT_APP_SERVER_BASE_URL}/uploadedImages/img_${item.imageTitle}.${item.imageFormat}`} 
                            alt="" />
                        </div>
                    </span>
                  </td>
                  <td>
                    <span className="table-text-dark db">
                        <div dangerouslySetInnerHTML={{ __html: item?.description }} />
                        </span>
                  </td>
                                
                  <td>
                    <div className="mr-5">
                      {/* <a href="#" className="mr-3"><img src={edit} alt="edit" /></a> */}
                      <a href="#" onClick={()=>deletePost(item?._id)}><img src={Delete} alt="Delete" /></a>
                    </div>
                  </td>
                </tr>
              ))}
            
              </tbody>
            </table>
          </div>
          <div className="d-flex justify-content-center">
            <Stack spacing={2}>
              <Pagination count={Math.ceil(noOfPost/limit)} page={page} onChange={handleChange} />
            </Stack>
          </div>
      </div>

    </div>

    
      </>
    )
  }

  export default AllAdminPost